// Things related to the scenes page
const Scenes = {
	staticTextBubble: null, // The text bubble with the static text shown on both pages
	notificationBubble : null,
	shareGroupID: null,
	inviteURL: null, // The invte URL that can be copied to the clipboard
	copyInviteURLtoClipboard: () => {
		if (Scenes.inviteURL) {
			copyToClipboard(Scenes.inviteURL);
			CP.notify("Invite URL copied to clipboard", false);
		} else CP.showErrorMessage("Invite URL is not available.");
	},
	createInviteCode: async () => {
		if (Scenes.shareGroupID) {
			CP.notify("Generating new scene sharing code", true);
			let result = await Cloud.getNewInviteCode(Scenes.shareGroupID, 1); // Usage = 1 because it's a sharing group
			if (result.success) {
				CP.notify('New scene sharing code: ' + result.inviteCode, false);
				getel('inviteCodeText').textContent = result.inviteCode;
			} else CP.showErrorMessage("Failed to get scene sharing code!");
		} else CP.showErrorMessage("Can't get a scene sharing code unless a sharing group has already been created.");
	},
	setStaticText: (share) => {
		if (!Scenes.staticTextBubble)
			Scenes.staticTextBubble = new TextBubble(getel('staticMessageArea'), "", TextBubbleStyle.Simple, null, false);
		Scenes.staticTextBubble.setText(getel(share ? 'sceneMessage2' : 'sceneMessage1').innerHTML, true);
	},
	// Swap between the main scene div and the share div
	// bool share: If true then the share div becomes the visible one.
	swapDivs: (share) => {
		Scenes.shareGroupID = null;
		makeElementHidden(getel('sharingInfoDiv'));
		let mainSceneDiv = getel('mainSceneDiv');
        let shareDiv = getel('shareDiv');
		let backArrow = getel('scenesPanelBackArrow');

		Scenes.setStaticText(share);

        if (share) {
			makeElementHidden(mainSceneDiv);
            makeElementVisible(shareDiv);
			makeElementVisible(backArrow);
        } else {
			makeElementHidden(backArrow);
			makeElementHidden(shareDiv);
            makeElementVisible(mainSceneDiv);
        }
	},
	createSharingGroupAndCopy: async (entryData) => {
		CP.notify("Sharing", true);
		let result = await Cloud.createGroup(1); // Usage is 1 here because it's a sharing group
		if(result.success) {
			let inviteCode = result.inviteCode;
			Scenes.shareGroupID = result.encryptionKey;
			let indexRes = await Cloud.addToIndex(entryData.entryHash, entryData.entryValue,
				{ groupID : Scenes.shareGroupID, version : Constants.CurrentVersion });
			console.log(indexRes);
			if (indexRes.success) {
				CP.notify("Successfully created share group and added scene to it.", false);
				getel('inviteCodeText').textContent = inviteCode;
				Scenes.inviteURL = `${getSiteBaseUrl()}/sharedscene.html?sharegroupid=${getBytesAsURIComponent(Scenes.shareGroupID)}`;
				makeElementVisible(getel('sharingInfoDiv'));
			}
			else CP.showErrorMessage("Failed to add scene to sharing group!");
		} else {
			CP.showErrorMessage("Failed to create a sharing group!");
		}
	},
	openShareDiv: (entryData) => {
		Scenes.swapDivs(true);
		if (entryData.entryValue.File?.Filename != null)
			getel('sceneName').innerHTML = entryData.entryValue.File.Filename;
		Scenes.createSharingGroupAndCopy(entryData);
	},
	fillTable: async () => {
		getEntriesAndFillTable(
			getel('ScenesTableBody'),
			entry => entry.entryValue.File?.ArkioScene != null, null,
			(tableBod, tableDat) => fillTableWithEntries(tableBod, tableDat,
				{ enableSharing : true, enableDownload : true, enableRemove : true, alwaysUseIcons : true, hideStatusIcon : true },
				{
					onErr : CP.showErrorMessage,
					onWarn: CP.showWarningMessage,
					onShareButtonClicked : (entryData) => Scenes.openShareDiv(entryData),
					onEntryRemoved : async (indexFile) => {
						// TODO maybe use indexFile here instead of getting it from the cloud.
						// See code in getEntriesAndFillTable, to see how that is done.
						await Scenes.fillTable();
					}
				}));
	},
	DOMContentLoaded : () => {
		CP.initMsgManager(getel('errorArea'), getel('staticMessageArea'), getel('notificationArea'));
		//TODO need to find a way to refactor this
		// there is similar code to this on another place
		document.getElementById("copyButtonSpan").addEventListener("click", async () => {
			CP.notify("Copying scene sharing code", true);
			let inviteCode = getel('inviteCodeText').innerHTML;
			await copyToClipboard(inviteCode);
			CP.notify("Scene sharing code " + inviteCode + " copied to clipboard", false);
		});
	},
	bodyOnLoad: () => {
		initPage();
		Scenes.setStaticText(false);
		Scenes.fillTable();
	}
};
